<template>
  <div class="d-flex flex-root">
    <div class="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
      <!--begin::Content-->
      <div class="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-4 pb-lg-0 pt-4 pb-6 bg-white">
        <!--begin::Wrapper-->
        <div class="login-content d-flex flex-column pt-lg-0 pt-12">
          <!--begin::Logo-->
<!--          <a href="#" class="login-logo pb-xl-20 pb-15">-->
<!--            <img :src="logo" class="max-w-300px align-center flex-fill" alt="" />-->
<!--          </a>-->
          <router-view></router-view>
        </div>
        <!--end::EndWrapper-->
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <!--end::Aside-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "./src/assets/sass/pages/login/login-4";
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    logo() {
      return process.env.BASE_URL + "media/logos/komnasham-03.png";
    }
  }
};
</script>
